var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "table", staticClass: "table-panel-wrapper" }, [
    _c(
      "table",
      { staticClass: "table-panel-dra" },
      [
        _c(
          "draggable",
          {
            attrs: {
              animation: "500",
              "force-fallback": "true",
              filter: ".forbid",
              delay: "150",
              handle: ".move",
              move: _vm.onMove,
            },
            on: { update: _vm.onUpdate, start: _vm.onStart, end: _vm.onEnd },
            model: {
              value: _vm.dataList,
              callback: function ($$v) {
                _vm.dataList = $$v
              },
              expression: "dataList",
            },
          },
          [
            _c(
              "tr",
              { staticClass: "forbid", attrs: { id: "trForbid" } },
              [
                _vm._l(_vm.headers, function (item) {
                  return _c("th", { key: item.prop }, [
                    _vm._v(_vm._s(item.label)),
                  ])
                }),
                _c("th", [_vm._v("操作")]),
              ],
              2
            ),
            _vm._l(_vm.usrDataList, function (item, index) {
              return _c(
                "tr",
                { key: "tr" + index, staticClass: "item move" },
                [
                  _vm._l(_vm.headers, function (items) {
                    return _c("td", { key: "td" + items.prop + index }, [
                      item.id !== "head" && items.prop !== "scCodewordList"
                        ? _c(
                            "div",
                            {
                              staticClass: "cell el-tooltip move",
                              style: { width: _vm.cellWidth(item[items.prop]) },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(item && item[items.prop]) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                      items.prop === "scCodewordList"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  staticClass: "checkboxgroup",
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleCheckBoxChange(
                                        item,
                                        index
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.selectList,
                                    callback: function ($$v) {
                                      _vm.$set(item, "selectList", $$v)
                                    },
                                    expression: "item.selectList",
                                  },
                                },
                                _vm._l(item.scCodewordList, function (check) {
                                  return _c("el-checkbox", {
                                    key: check.scCodewordValue,
                                    attrs: { label: check.scCodewordValue },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  }),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "btn-wrapper" },
                      [
                        _c("v-button", {
                          staticClass: "control-btn btn",
                          attrs: {
                            type: "success",
                            text: item.typeIsUse == 1 ? "停用" : "启用",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleStopOrStart(item)
                            },
                          },
                        }),
                        _c("v-button", {
                          staticClass: "delete-btn btn",
                          attrs: { type: "danger", text: "删除" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDeleteClick(item, index)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            }),
            _vm._l(_vm.addList, function (item, index) {
              return _c("tr", { key: index, staticClass: "item move" }, [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.usrDataList.length + index + 1) +
                      "\n          "
                  ),
                ]),
                _c(
                  "td",
                  [
                    item.showSelect
                      ? _c(
                          "v-select2",
                          _vm._b(
                            {
                              staticClass: "select2",
                              attrs: {
                                subjoin: _vm.subjoin,
                                placeholder: "请搜索人员类型",
                                width: 200,
                              },
                              on: {
                                onChange: function ($event) {
                                  return _vm.communityChange($event, index)
                                },
                              },
                              model: {
                                value: item.labelId,
                                callback: function ($$v) {
                                  _vm.$set(item, "labelId", $$v)
                                },
                                expression: "item.labelId",
                              },
                            },
                            "v-select2",
                            _vm.communityParams,
                            false
                          )
                        )
                      : _vm._e(),
                    !item.showSelect
                      ? _c("div", [_vm._v(_vm._s(item.labelName))])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("td", [
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          staticClass: "checkboxgroup",
                          model: {
                            value: item.selectList,
                            callback: function ($$v) {
                              _vm.$set(item, "selectList", $$v)
                            },
                            expression: "item.selectList",
                          },
                        },
                        _vm._l(item.typeList, function (check, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: check.scCodewordValue },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "btn-wrapper" },
                    [
                      item.showSelect
                        ? _c("v-button", {
                            attrs: { type: "danger", text: "删除" },
                            on: {
                              click: function ($event) {
                                return _vm.dele(index, item)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ])
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }