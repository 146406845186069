<template>
  <div class="projectTypeConfig-container">
		<p>
			人员类型信息
			<v-button style="margin-left: 20px" class="add-btn" type="primary" text="新增" @click="add"></v-button>
		</p>
		<div class="form">
			<table-draggable
				ref="draggable"	
				:headers="headers" 
				:tableData="tableData" 
				operateColumnWidth="120"
				class="table-panel"
				@update:tableData="handleUpdate"
				:addList="addList"
				@delete="del"
				@listChange="handleListChange"
				@deleteorstop="deleteorstop"
				:saveStatus="saveStatus"
			>
			</table-draggable>
			<br>
			<p class="ps">(备注：可进行拖拽排序)</p>
		</div>
		
		<v-button class="save-btn" type="success" text="保存" @click="save"></v-button>
		<v-button class="back" type="primary" text="返回" @click="back"></v-button>
  </div>
</template>
<script>
import { communityParams } from 'common/select2Params'
import { getConfigTypeList, getPeopleTypeList, addPeopleType } from './api'
import TableDraggable from './draggableTable.vue'
export default {
	name: 'projectTypeConfig',
  components: {
    // TablePanel,
		TableDraggable	
  },
  data () {
    return {
			headers: [{
				prop: 'sort',
				label: '排序'
			}, {
        prop: 'labelName',
        label: '人员类型',
				width: 150
      }, {
        prop: 'scCodewordList',
        label: '录入信息列表'
      }],
			tableData: [],
			communityId: '',
      communityParams,
			addList: [],
			typeList: [],
			finalList: [],
			saveStatus: false
    }
  },
  created () {
		this.handleGetConfigTypeList()
		this.$setBreadcrumb('人员类型配置')
  },
  methods: {
		operation () {},
		communityChange () {},
		async handleGetConfigTypeList () {
			const id = this.$route.query.id
			const res = await this.$axios.get(getConfigTypeList, { params: { communityId: id } })
			if (res.status === 100) {
				this.tableData = res.data
				res.data.map((item, index) => {
					this.tableData[index].selectList = []
				})
				res.data.map((item, index) => {
					item.scCodewordList.map(list => {
						if (list.isUse === 1) {
							this.tableData[index].selectList.push(list.scCodewordValue)
						}
					})
				})
			}
		},
		async add () {
			const item = {
				selectList: [],
				typeList: [],
				showSelect: true
			}
			// console.log(this.addList);
			const res = await this.$axios.get(getPeopleTypeList)
			if (res.status === 100) {
				item.typeList = res.data
			}
			this.addList = [...this.addList, item]
			this.$nextTick(() => {
				this.$refs.draggable.$refs.table.scrollTop = this.$refs.draggable.$refs.table.scrollHeight;
			})
		},
		del (index) {
			this.addList.splice(index, 1)
		},
		async save () {
			// 去除组件的临时保存按钮，将逻辑转移到这里进行判断再合并保存
			// 1. 判断是否选择人员类型 & 选择的人员类型是否已存在
			let tableData = [...this.tableData]
			if (this.finalList.length) {
				tableData = this.finalList
			}
			let addList = this.$refs.draggable.addList || []
			if (!this.finalList.length && !addList.length) {
				return
			}
			if (addList.length) {
				for (let j = 0; j < addList.length; j++) {
				// addList.some((item, index) => {
					if (!addList[j].labelName) {
						this.$message({
							message: '请先选择人员类型信息',
							type: 'error'
						});
						return
					}
					for (let i = 0; i < tableData.length; i++) {
						if (addList[j].labelName === tableData[i].labelName) {
							this.$message.error(`${tableData[i].labelName}, 该人员类型已存在`);
							return
						}
						if (!this.finalList.length) {
							// 在原有数据没有修改情况下，重新整理 tableData
							tableData[i].inuser = this.$store.state.userInfo.id
							tableData[i].scCodewordCodeList = []
							tableData[i].scCodewordList.map(type => {
								tableData[i].selectList.map(item => {
									if (type.scCodewordValue === item) {
										tableData[i].scCodewordCodeList.push(Number(type.scCodewordCode))
									}
								})
							})
						}
					}
					addList[j].communityId = this.$route.query.id
					addList[j].communityName = this.$route.query.name
					addList[j].inuser = this.$store.state.userInfo.id
					addList[j].scCodewordCodeList = []
					addList[j].typeList.map(type => {
						addList[j].selectList.map(item => {
							if (type.scCodewordValue === item) {
								addList[j].scCodewordCodeList.push(Number(type.scCodewordCode))
							}
						})
					})
				}
			}
			console.log(tableData, addList);
			const final = [...tableData, ...addList]
			const list = []
			final.map(item => {
				list.push({
					communityId: item.communityId,
					communityName: item.communityName,
					inuser: item.inuser,
					labelId: item.labelId,
					labelName: item.labelName,
					scCodewordCodeList: item.scCodewordCodeList
				})
			})
			const res = await this.$axios.post(addPeopleType, list)
			if (res.status === 100) {
				this.$message({
          message: '保存成功',
          type: 'success'
        });
				this.handleGetConfigTypeList()
				this.addList = []
				this.finalList = []
				this.saveStatus = !this.saveStatus
			}

		},
		handleUpdate (e) {
			console.log(e);
			this.tableData = e
		},
		back () {
			this.$router.back()
		},
		handleListChange (list) {
			this.finalList = list
			// list.forEach(item => {
			// 	if (item) {
			// 		this.finalList.push(item)
			// 	}
			// })
			console.log(list);
		},
		deleteorstop () {
			this.handleGetConfigTypeList()
		}
	}
}
</script>
<style lang="scss" scoped>
  .projectTypeConfig-container {
    text-align: left;
    background: #fff;
    padding: 0 20px;
		margin-top: -30px;
		// .form {
		// 	padding-left: 20px;
		// }
		::v-deep .table-panel {
			margin-top: 20px;
			width: 100%;
			max-height: 600px;
			overflow-y: scroll;
			overflow-x: hidden;
		}
		.add-btn {
			margin: 20px auto;
		}
		.back {
			position: absolute;
			top: 20px;
			right: 20px;
		}
  }
  .formHeight {
    height: calc(100% -  55px);
  }
	.ps {
		width: 74%;
		text-align: right;
	}
</style>
