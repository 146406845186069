<template>
  <div class="table-panel-wrapper" ref="table">
    <table class="table-panel-dra">
      <draggable
        v-model="dataList"
        animation="500"
        force-fallback="true"
        filter=".forbid"
        delay="150"
        handle=".move"
        :move="onMove"
        @update="onUpdate"
        @start="onStart"
        @end="onEnd">
        <!-- 表头 -->
        <tr class="forbid" id="trForbid">
          <th v-for="item in headers" :key="item.prop" >{{item.label}}</th>
          <th>操作</th>
        </tr>
        <tr class="item move" v-for="(item, index) in usrDataList" :key="'tr' + index">
					<td v-for="items in headers" :key="'td' + items.prop + index">
						<div
							class="cell el-tooltip move"
							v-if="item.id !== 'head' && items.prop !== 'scCodewordList'"
							:style="{'width': cellWidth(item[items.prop])}">
								{{item && item[items.prop]}}
						</div>
						<div
							v-if="items.prop === 'scCodewordList'"
						>
            <!-- {{item.selectList}} -->
							<el-checkbox-group @change="handleCheckBoxChange(item, index)" class="checkboxgroup" v-model="item.selectList">
								<el-checkbox v-for="check in item.scCodewordList" :key="check.scCodewordValue" :label="check.scCodewordValue"></el-checkbox>
							</el-checkbox-group>
						</div>
					</td>
					<td>
            <div class="btn-wrapper">
              <v-button class="control-btn btn" type="success" :text="item.typeIsUse == 1 ? '停用' : '启用'" @click="handleStopOrStart(item)"></v-button>
              <v-button class="delete-btn btn" type="danger" text="删除" @click="handleDeleteClick(item, index)"></v-button>
            </div>
					</td>
        </tr>
        <tr class="item move" v-for="(item, index) in addList" :key="index">
          <td>
            {{usrDataList.length + index + 1}}
          </td>
          <td>
						<v-select2
              class="select2"
              v-model="item.labelId"
              v-bind="communityParams"
              :subjoin="subjoin"
              @onChange="communityChange($event, index)"
              placeholder="请搜索人员类型"
              :width="200"
              v-if="item.showSelect"
            ></v-select2>
            <div v-if="!item.showSelect">{{item.labelName}}</div>
          </td>
					<td>
						<div>
							<el-checkbox-group class="checkboxgroup" v-model="item.selectList">
								<el-checkbox v-for="(check, index) in item.typeList" :key="index" :label="check.scCodewordValue"></el-checkbox>
							</el-checkbox-group>
						</div>
					</td>
					<td>
            <div class="btn-wrapper">
              <!-- <v-button v-if="item.showSelect" type="success" text="保存" @click="save(index, item)"></v-button> -->
              <v-button v-if="item.showSelect" type="danger" text="删除" @click="dele(index, item)"></v-button>
            </div>
					</td>
        </tr>
      </draggable>
    </table>
  </div>
</template>

<script>
// import { communityParams } from 'common/select2Params'
import { Checkbox, CheckboxGroup } from 'element-ui'
import draggable from 'vuedraggable'
import { deleteOrStopPeople, getPeopleLabel, getPeopleTypeList, addPeopleType, exchangeSort, recoverPeople } from './api'
// @group 业务组件
// @title TablePanel 表格
export default {
  name: 'table-draggable',
  components: {
    draggable,
    elCheckbox: Checkbox,
    elCheckboxGroup: CheckboxGroup
  },
  props: {
    // 是否显示表头
    showHeader: {
      type: Boolean,
      default: true
    },
    // 表头数据
    headers: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 列表数据
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 接口请求数据成功后的回调函数，可以对数据做一些预处理，第一个参数为当前行的数据对象，第二个参数为当前行数
    handleData: Function,
    // 合并行或列的计算方法
    spanMethod: Function,
    // 新增行
    addList: {
      type: Array,
      default: () => {
        return []
      }
    },
    typeList: {
      type: Array,
      default: () => {
        return []
      }
    },
    saveStatus: {
      type: Boolean
    }
  },
  data () {
    return {
      selectedData: [],
      dataList: [{id: 'head'}],
      usrDataList: [],
      isSelectAll: false,
      communityParams: {
        searchUrl: getPeopleLabel,
        request: {
          text: 'labelName',
          value: 'id'
        },
        response: {
          text: 'labelName'
        }
      },
			communityId: '',
      scCodewordList: [
        {
          scCodewordValue: '身份证信息'
        },
        {
          scCodewordValue: '工作证信息'
        },
        {
          scCodewordValue: '拜访业主信息'
        }
      ],
      selectList: [],
      startIndex: 0,
      endIndex: 0,
      changeList: [],
      addSaveList: [],
      subjoin: {
        communityId: this.$route.query.id
      }
    }
  },
  watch: {
    tableData (newValue) {
      this.dataList = [{id: 'head'}, ...newValue]
      this.initSlot()
    },
    // 保存后清空 addSaveList,防止添加重复数据
    saveStatus () {
      this.addSaveList = []
    }
  },
  computed: {
    isShowHeader () {
      if (this.showHeader) {
        if (this.headers && this.headers.length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
    async handleDeleteOrStopPeople (flag, item, index) {
      const scCodewordCodeList = []
      item.scCodewordList.map(code => {
        scCodewordCodeList.push(Number(code.scCodewordCode))
      })
      const res = await this.$axios.post(`${deleteOrStopPeople}?flag=${flag}`, [{
        id: item.id,
        labelId: item.labelId,
        scCodewordCodeList
      }])
      if (res.status === 100) {
        this.$message({
          message: flag === 1 ? '删除成功' : '操作成功',
          type: 'success'
        });
        this.$emit('deleteorstop')
        this.changeList.splice(index, 1)
      }
    },
    communityChange (e, index) {
      if (e.labelName && this.addList.length) {
        for (let i = 0; i < this.addList.length; i++) {
          if (this.addList[i].labelName === e.labelName) {
            this.$message.error(`${e.labelName}, 该人员类型已存在`);
            return
          }
        }
      }

      this.addList[index].labelName = e.labelName
    },
		async handleStopOrStart (item) {
      console.log(item);
      let confirmText = `确认${item.typeIsUse === 2 ? '启用' : '停用'}？`
      let result = await this.$confirm(confirmText);
      if (item.typeIsUse === 2) {
        result && this.handleRecoverPeople(item)
        return
      }
      result && this.handleDeleteOrStopPeople(2, item)
    },
		async handleDeleteClick (item, index) {
      let confirmText = '确认删除？';
      let result = await this.$confirm(confirmText);
      result && this.handleDeleteOrStopPeople(1, item, index)
    },
    cellWidth (text) {
      let maxWidth = 'auto'
      text = text + ''
      if (text.length > 15 ) {
        maxWidth = '126px'
      }
      return maxWidth
    },
    onUpdate (e, arr) {
      // console.log('onUpdate', e, arr)
    },
    onStart (e) {
      console.log(e)
      this.startIndex = e.oldIndex - 1
    },
    onEnd (e) {
      console.log(e);
      this.endIndex = e.newIndex - 1
      let dataList = []
      console.log(this.dataList);
      console.log(this.usrDataList);
      this.dataList.forEach((res) => {
        this.usrDataList.forEach(item => {
          if (item.labelId === res.labelId) {
            res = item
          }
        })
        if (res && res.id !== 'head') dataList.push(res)
      })
      this.$emit('update:tableData', dataList)
      this.handleExchangeSort(this.usrDataList)
    },
    onMove(e) {
      if (e.related.id == 'trForbid') return false;
      return true;
    },
    initSlot () {
      let tableData = this.tableData
      let _this = this
      this.usrDataList = []
      this.selectedData = []
      this.isSelectAll = false
      Array.isArray(tableData) && tableData.forEach((row, rowIndex) => {
        let data = {...row};
        data.isSelect = false
        this.usrDataList.push(data)
        this.usrDataList.map((item, index) => {
          // checkbox 排序
          this.usrDataList[index].scCodewordList = item.scCodewordList.sort((a, b) => {
            return Number(a.scCodewordCode) - Number(b.scCodewordCode)
          })
        })
        if (_this.handleData && typeof _this.handleData === 'function') {
          _this.handleData(row, rowIndex)
        }
      })
    },
    async save (index, item) {
      const name = item.labelName
      const params = {
        communityId: this.$route.query.id,
        communityName: this.$route.query.name,
        inuser: this.$store.state.userInfo.id,
        labelId: item.labelId,
        labelName: item.labelName,
        scCodewordCodeList: []
      }
      for (let i = 0; i < this.usrDataList.length; i++) {
        if (this.usrDataList[i].labelName === name) {
          this.$message.error('该人员类型已存在');
          return
        }
      }
      item.typeList.map(type => {
        item.selectList.map(item => {
          if (type.scCodewordValue === item) {
            params.scCodewordCodeList.push(Number(type.scCodewordCode))
          }
        })
      })
      this.addList[index].showSelect = false
      this.addSaveList.push(params)
      const data = this.handleWashDataList(this.usrDataList)
      this.changeList = [...data, ...this.addSaveList]
      this.$emit('listChange', this.changeList)
    },
    dele (index) {
      this.$emit('delete', index)
      this.addSaveList.splice(index, 1)
    },
    async handleExchangeSort (list) {
      const startId = list[this.startIndex].id
      const endId = list[this.endIndex].id
      console.log(startId, endId);
      const res = await this.$axios.post(exchangeSort, {
        idOne: startId,
        idTwo: endId,
        updateUser: this.$store.state.userInfo.id
      })
      this.$emit('deleteorstop')
    },
    // 记录修改过的行
    handleCheckBoxChange (item, index) {
      const data = this.handleWashDataList(this.usrDataList)
      this.changeList = [...data]
      this.$emit('listChange', this.changeList)
    },
    // 处理list数据,用于保存
    handleWashDataList (list) {
      const data = []
      let params = {
        communityId: '',
        communityName: '',
        inuser: this.$store.state.userInfo.id,
        labelId: '',
        labelName: '',
        scCodewordCodeList: []
      }
      list.map(item => {
        params.communityId = item.communityId
        params.communityName = item.communityName
        params.labelId = item.labelId
        params.labelName = item.labelName
        item.scCodewordList.map(type => {
          item.selectList.map(sel => {
            if (type.scCodewordValue === sel) {
              params.scCodewordCodeList.push(Number(type.scCodewordCode))
            }
          })
        })
        data.push(params)
        params = {
          communityId: '',
          communityName: '',
          inuser: this.$store.state.userInfo.id,
          labelId: '',
          labelName: '',
          scCodewordCodeList: []
        }
      })
      return data
    },
    async handleRecoverPeople (item) {
      const params = [
        {
          id: item.id,
          labelId: item.labelId
        }
      ]
      const res = await this.$axios.post(recoverPeople, params)
      if (res.status === 100) {
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.$emit('listChange', this.changeList)
        this.$emit('deleteorstop')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .table-panel-wrapper {
    width: 100%;
    height: 100%;
    .table-panel-dra {
      width: 100%;  
      color: #606266;
      // table-layout: fixed;
      border-collapse: collapse;
      border-spacing: 0;
      tr {
        background-color: #FFF;
        td,th {
          padding: 12px 0;
          min-width: 0;
          box-sizing: border-box;
          text-overflow: ellipsis;
          vertical-align: middle;
          text-align: left;
          border: 1px solid #EBEEF5;
        }
        th {
          background-color: #FFF;
          text-align: center;
          user-select: auto;
          overflow: hidden;
          color: #909399;
        }
        td {
          text-align: center;
          transition: background-color .25s ease;
          input[type="checkbox"] {
            border: 1px solid #DCDFE6;
          }
        }
      }
      tr:nth-of-type(odd) {
        td {
          background: #FAFAFA;
        }
      }
      th>.cell {
        display: inline-block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        vertical-align: middle;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
      }
      .cell {
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-all;
        line-height: 23px;
        padding-right: 14px;
        padding-left: 14px;
      }
      .cell.el-tooltip {
        white-space: nowrap;
        min-width: 50px;
      }
      .forbid {
        th {
          padding: 10px 100px;
        }
      }

    }
    .vnode-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .checkboxgroup {
      padding: 0 30px;
      // display: flex;
      // flex-direction: column;
      // align-items: flex-start;
    }
    .btn {
      color: #409EFF;
      cursor: pointer;
    }
    .btn-wrapper {
      display: flex;
      justify-content: space-around;
    }
    .select2 {
      padding: 0 30px;
    }
  }

</style>
