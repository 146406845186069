var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "projectTypeConfig-container" },
    [
      _c(
        "p",
        [
          _vm._v("\n\t\t\t人员类型信息\n\t\t\t"),
          _c("v-button", {
            staticClass: "add-btn",
            staticStyle: { "margin-left": "20px" },
            attrs: { type: "primary", text: "新增" },
            on: { click: _vm.add },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form" },
        [
          _c("table-draggable", {
            ref: "draggable",
            staticClass: "table-panel",
            attrs: {
              headers: _vm.headers,
              tableData: _vm.tableData,
              operateColumnWidth: "120",
              addList: _vm.addList,
              saveStatus: _vm.saveStatus,
            },
            on: {
              "update:tableData": _vm.handleUpdate,
              delete: _vm.del,
              listChange: _vm.handleListChange,
              deleteorstop: _vm.deleteorstop,
            },
          }),
          _c("br"),
          _c("p", { staticClass: "ps" }, [_vm._v("(备注：可进行拖拽排序)")]),
        ],
        1
      ),
      _c("v-button", {
        staticClass: "save-btn",
        attrs: { type: "success", text: "保存" },
        on: { click: _vm.save },
      }),
      _c("v-button", {
        staticClass: "back",
        attrs: { type: "primary", text: "返回" },
        on: { click: _vm.back },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }